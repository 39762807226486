import * as React from "react";
import { Box } from "@components/box";
import { GrayPhrase, Paragraph } from "@components/typography";

export const TextOnly = (props:any) => (
  <Box className="mx-auto max-w-[1320px] my-[5.5rem] lg:max-w-[660px] md:max-w-[540px] sm:px-[22px] sm:my-[2.75rem]">
    <Box className="max-w-[660px]">
      <Paragraph>
        <GrayPhrase>
          {props.children}
        </GrayPhrase>
      </Paragraph>
    </Box>
  </Box>
);
