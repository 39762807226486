import * as React from "react";
import { graphql } from "gatsby";
import { getImage } from "gatsby-plugin-image";

import { PageLayout, SEO } from "@components/page-layout";
import { GrayPhrase } from "@components/typography";
import { BigListSection } from "@containers/big-list";
import { ProvincesSection } from "@containers/provinces";
import { CostSimulatorSection } from "@containers/cost-simulator";
import { FullscreenSection } from "@containers/fullscreen";
import { Box } from "@components/box";
import { TextOnly } from "@components/text-only";
import { BIGLISTHOME } from "@containers/big-list/big-list-data";
import { MainHeaderContainer } from "@containers/header";
import { BigListItem } from "@brainfinance/icash-component-library";

const Calculator = (props: any) => (
  <PageLayout>
    <SEO title="Calculator" />
    <MainHeaderContainer
      src={getImage(props.data.file)?.images.fallback?.src || ""}
      srcSet={getImage(props.data.file)?.images.fallback?.srcSet || ""}
      description=""
      buttonOff
    >
      <GrayPhrase>Best</GrayPhrase> low rate loan
      <br />
      calculator
    </MainHeaderContainer>
    <CostSimulatorSection />
    <TextOnly className="my-[4.5rem] sm:my-[2.75rem]">
      With our lower costs and the flexibility to repay your loan over several instalments, iCASH is
      the #1 choice in online loans in Canada.
    </TextOnly>
    <Box className="h-[1px]" />
    <ProvincesSection
      title="Provinces. Interest fees"
      gray="Interest fees"
      description="The cost of borrowing is calculated according to the payday loan legislation of each province."
    />
    <Box className="h-[1px]" />
    <TextOnly>
      When you need a short-term loan or personal loan, it is easy to get confused as to which
      financial solution is right for your specific situation. There are many factors that need to
      be considered such as payback period, wait time to receive your funds, interest rates,
      incentive and loyalty programs, and so much more! In order to help simplify the
      decision-making process, iCASH has put together this easy-to-follow short-term loans
      calculator page with a detailed breakdown of everything you need to know before you borrow!
      <br />
      <br />
      The first thing to consider is the period needed to pay back your loan. At iCASH we are proud
      to offer our customers the option to pay back in 1 or multiple installments*. This allows our
      customers the flexibility to properly budget as well as the freedom to decide what works best
      for their situation. The loan calculator below will detail the exact repayment instalment
      amount based on the payment terms desired. This way you can see the exact amount per paycheck
      that you would need to budget.
      <br />
      <br />
      In addition to the amount of your repayments, one of the most frequently asked questions is
      the interest rate on these loans. A common misconception is that the rates are exorbitant!
      Nothing could be further from the truth. After entering the required information in the loan
      calculator below, the exact amount of interest charged per loan will be detailed. There are no
      additional fees or hidden charges. Transparency is part of iCASH’s commitment to responsible
      lending.
      <br />
      <br />
      And while doing your comparison shopping for the right personal loan for you, look for the
      lender that truly values your business. At iCASH, we developed a unique reward program in
      order to offer something as a Thank You to our valued customers. Our Cashback program earns
      our customers a percentage back on the cost of borrowing – even after the very first loan!
    </TextOnly>
    <FullscreenSection />
    <BigListSection
      title="Why choose an iCash instant loan"
      gray="instant loan"
      description="iCash offers easy, fast, and secure online loans to Canadians, 24 hours a day and 7 days a week. Funds are sent via e-Transfer and received the same day."
      buttonText="Learn why you should choose iCash"
      button={true}
    >
      {BIGLISTHOME.map((item, ndx) => (
        <BigListItem key={ndx} {...item} />
      ))}
    </BigListSection>
  </PageLayout>
);

export default Calculator;

export const pageQuery = graphql`
  query {
    file(relativePath: {eq: "fullscreen-background.jpg"}) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP], width: 1160)
      }
    }
  }
`