import * as React from "react";
import {
  Layout,
  LayoutHeader,
  LayoutTitle,
  LayoutDescription,
} from "@brainfinance/icash-component-library";
import { LayoutProps } from "@types";

import { generateTitle } from "@helpers/generateTitle";
import { LoanCalculator } from "@containers/loan-calculator";

export const CostSimulatorSection = (props: LayoutProps) => (
  <Layout className={props.containerClass}>
    <LayoutHeader centered>
      <LayoutTitle className="max-w-[660px]">
        {generateTitle({
          title: props.title,
          gray: props.gray,
        })}
      </LayoutTitle>
      <LayoutDescription className="max-w-[660px]">{props.description}</LayoutDescription>
    </LayoutHeader>
    <LoanCalculator />
  </Layout>
);

CostSimulatorSection.defaultProps = {
  title: "Find out your loan payments",
  gray: "loan payments",
  description:
    "Determining your loan payments can be confusing. iCash wants to make this process easy and intuitive. We have put in place a calculator that tells you know exactly how much you will pay on each payment. Start below!",
};
