import React from "react";
import { GatsbyLink } from "@components/gatsby-link";

export const BIGLISTHOME = [
  {
    title: "Getting a quick online loan",
    children:
      "Here at iCASH, we're focused on helping Canadians address their cash flow needs - we pay more attention to your repayment capacity than to yourcredit score."
  },
  {
    title: "No documents required",
    children:
      "Getting an unsecured loan through iCASH is easy. The online loan application process is in real-time, and we have all the tools to evaluate your application without asking for any documents. There's no waiting in line and faxing long forms, so this way you finish fast and get your money faster."
  },
  {
    title: "No hidden charges",
    children:
      "We are committed to responsible and transparent lending. We make sure all the fees involved in your loan are displayed in a clear and understandable manner so it gives you the option to take an enlightened decision."
  },
  {
    title: "Get approved in just minutes 24/7",
    children:
      "We know that the sooner you get your cash loan, the sooner you go back to the things you care about. We use a sophisticated decision system that renders a decision in just minutes."
  },
  {
    title: "Get an e-Transfer in 2 minutes!",
    children:
      "After having signed your loan agreement, your funds will be sent by e-transfer and you will receive them in less than 2 minutes!"
  },
  {
    title: "Earn unlimited cashback",
    children:
      "Our customers have the opportunity to earn a percentage back on the cost of borrowing of every loan paid back! Earn and redeem points; and continue saving like this!"
  }
];

export const BIGLISTHOMEOLD = [
  {
    title: "No wait",
    children:
      "Our loans applications are completed entirely online and do not require any documents. Once completed, you receive instant approval and, after electronically signing your contract, your funds are sent via e-Transfer within 5 minutes - even on weekends and holidays!",
    icon: "👏"
  },
  {
    title: "Get approved",
    children:
      "Simply fill in our easy, online application and you can be instantly approved for your loan. Choose the repayment schedule that best fits your needs.",
    icon: "⏰"
  },
  {
    title: "No paperwork",
    children:
      "Apply from the comfort of your home - no more faxing documents or waiting in line. Our payday loans are flexible and convenient.",
    icon: "🤳"
  },
  {
    title: "Grace period",
    children:
      "We add a 5-day grace period before the start of your repayment on your next payday. This allows you an extra 5 days to pay back your loan, with no extra fee.",
    icon: "📅"
  },
  {
    title: "Flexpay repayment",
    children:
      "iCash gives you the option to repay your loan in one or in multiple payments - without any additional increase in interest or extra fees!",
    icon: "👍"
  },
  {
    title: "100% compliant",
    children: (
      <>
        As licensed lenders, we are fully compliant with provincial laws and regulations, and our transparent lending
        process means there are no hidden fees and no surprises.
        <br />
        <br />
        <a href="/" className="text-primary hover:text-primary-emphasis font-500">
          Know more
        </a>
      </>
    ),
    icon: "🥂"
  }
];

export const BIGLISTWHYICASH = [
  {
    title: "Our misson",
    children:
      "iCASH aims to provide transparent, responsible and fair-lending practices to help you reach your financial goals. We are always here to help with an online cash loan when unexpected expenses come up and you are short of money until your next payday. We are ready to lend you a hand to help you get your finances back on track."
  },
  {
    title: "About us",
    children:
      "iCASH is a Canadian lender that provides short-term loans to many customers all around Canada. We are proud to say that we own all the licences required to offer payday loans in every province. We offer our clients high quality services in a quick and safe manner. Our services are reliable, accessible and affordable to everyone."
  },
  {
    title: "Our commitment",
    children: (
      <>
        We are here to help you get your finances on track, not fall further into debt. When we receive your personal
        loan application, we make sure to approve you for a loan amount that you can easily repay. We also offer you the
        possibility to repay your loan in 1 or multiple payments for the same amount of interest. This way, you can rest
        easy knowing that repayments will be manageable for you, and that the short-term loan will be cleared fast.
        Since we are lenders with a conscience, we do not aim to get extra money out of our users by offering to lend
        them more money than they need and we won't try to catch you up with confusing financial jargon or hidden costs.
        Our payday loans are straightforward, secure, and sensible – real financial solutions for real people.
        <br />
        <br />
        <GatsbyLink to="/">Know more</GatsbyLink>
      </>
    )
  }
];
