import * as React from "react";
import { Fullscreen, FullscreenLeft, FullscreenRight } from "@brainfinance/icash-component-library";
import { FullscreenSectionProps } from "./full-screen";
// Images
import backgroundLeft from "@images/fullscreen-background.jpg";
import logo from "@images/icash-symbol-black-sized.svg";

export const FullscreenSection = (props:React.PropsWithChildren<FullscreenSectionProps>) => (
  <Fullscreen>
    <FullscreenLeft
      logo={logo}
      className="!bg-[#D7E0E7]"
      style={{ backgroundImage: `url(${props.src})` }}
    >
      <h2 className="global--heading mt-[2.5rem] sm:mt-[1.75rem]">{props.title}</h2>
    </FullscreenLeft>
    <FullscreenRight grayBg buttonText={props.buttonText}>
      {props.children}
    </FullscreenRight>
  </Fullscreen>
);

FullscreenSection.defaultProps = {
  title: "20% cashback",
  src: backgroundLeft,
  children: (<>
    <h3 className="global--subheading mb-[2rem] sm:mb-[22px]">
      Borrow and save.
      <br />
      Earn up to 20% in cashback!
    </h3>
    <p className="global--paragraph text-interface-300">
      iCash offers a unique Cashback program to reward our customers. You receive, in cashback, up to 20% of the cost
      of borrowing once your loan is repaid. Your cashback is sent directly to your bank account the same day. Real
      savings and real money!
    </p>
  </>
  )
}