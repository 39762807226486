// Images
import albertaImg from "@images/provinces/alberta-card.jpg";
import bcImg from "@images/provinces/bc-card.jpg";
import manitobaImg from "@images/provinces/manitoba-card.jpg";
import nbImg from "@images/provinces/nb-card.jpg";
import nsImg from "@images/provinces/ns-card.jpg";
import ontarioImg from "@images/provinces/ontario-card.jpg";
import peiImg from "@images/provinces/pei-card.jpg";

export const PROVINCES = [
  {
    title: "Alberta loans",
    children: "Payday loans for Alberta residents to help cover their immediate cash needs.",
    image: albertaImg,
    className: "mt-0"
  },
  {
    title: "British Columbia loans",
    children:
      "After having signed your loan agreement, your funds will be sent by e-transfer and you will receive them in less than 2 minutes!",
    image: bcImg,
    className: "mt-[2.75rem]"
  },
  {
    title: "Manitoba loans",
    children: "The funds will be sent by e-transfer  24/7.",
    image: manitobaImg,
    className: "mt-[5.5rem] sm:mt-0"
  },
  {
    title: "New Brunswick loans",
    children: "Funds are sent 24/7 and even on Holidays!",
    image: nbImg,
    className: "mt-[8.25rem] md:mt-0 sm:mt-[2.75rem]"
  },
  {
    title: "Nova Scotia loans",
    children:
      "Funds will be sent by e-transfer and you will receive them in less than 2 minutes.",
    image: nsImg,
    className: "mt-[11rem] md:mt-[2.75rem] sm:mt-0"
  },
  {
    title: "Ontario loans",
    children:
      "After having signed your loan agreement, your funds will be sent by e-transfer and you will receive them in less than 2 minutes!",
    image: ontarioImg,
    className: "mt-[13.75rem] md:mt-[5.5rem] sm:mt-[2.75rem]"
  },
  {
    title: "Prince Edward Island loans",
    children: "Funds are sent 24/7 and even on Holidays!",
    image: peiImg,
    className: "mt-[16.5rem]"
  }
];