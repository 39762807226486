import * as React from "react";

import { DiagonalCard, DiagonalCardsContainer } from "@brainfinance/icash-component-library";
import { generateTitle } from "../../helpers";
import { ProvincesProps } from "./provinces";
import { Box } from "@components/box";
import { PROVINCES } from "./province-data";

export const ProvincesSection = (props:ProvincesProps) => (
  <Box display="flex" className="my-[5.5rem] md:my-[2.75rem] flex-col justify-center">
    <Box className="mx-auto w-full max-w-[1320px] lg:max-w-[875px] md:max-w-[584px] sm:w-full sm:px-[22px]">
      {generateTitle({
        title: props.title,
        gray: props.gray,
      })}
      <p className="global--paragraph mt-[22px]">
        <span className="text-interface-300">
          {props.description}
        </span>
      </p>
    </Box>
    <DiagonalCardsContainer>
      {PROVINCES.map((province, ndx) => (
        <DiagonalCard key={ndx} {...province} />
      ))}
    </DiagonalCardsContainer>
  </Box>
);

ProvincesSection.defaultProps ={
  title: "Provinces of Canada we operate",
  gray: "we operate",
  description: "We provide payday cash advance loans in these provinces of Canada."
}