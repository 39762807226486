import * as React from "react";

import { BigList, BigListContainer } from "@brainfinance/icash-component-library";
import { BigListSectionProps } from "./big-list";
import { generateTitle } from "../../helpers";
import placeHolderImg from "@images/placeholder.png";

export const BigListSection = (props:React.PropsWithChildren<BigListSectionProps>) => (
  <BigList className="mx-auto !mt-[12rem] mb-[6rem] md:my-[3rem]">
    {generateTitle({
      title: props.title,
      gray: props.gray,
      fontClass: "big-list--title",
    })}
    <div className="big-list--paragraph-container ">
      <p className="global--paragraph text-interface-300">
        iCash offers easy, fast, and secure online loans to Canadians, 24 hours a day and 7 days a week. Funds are sent via e-Transfer and received the same day.
      </p>
    </div>
    <BigListContainer {...props}>
      {props.children}
    </BigListContainer>
  </BigList>
);

BigListSection.defaultProps = {
  title: "Why choose an iCash instant loan",
  gray: "instant loan",
  img: placeHolderImg,
  positionImg:
    "top-[6rem] left-0 lg:top-[13.5rem] md:top-[7.5rem] sm:left-[initial] sm:top-[initial] sm:right-[-20px] sm:bottom-[-20px]"
};
